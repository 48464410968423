<template>
  <b-card class="d-flex flex-column w-100 mb-3" style="max-height: -webkit-fill-available !important;">
    <div id="postDetail" class="row">
      <div class="col-12">
        <div class="d-flex">
          <!-- userimage -->
          <div class="user-img mr-3">
            <b-img  v-if="moduleData.image" :src="moduleData.image" style="object-fit: cover" alt="userimg" class="avatar-35" rounded="circle" fluid/>
            <b-avatar v-else class="logo-icon" :variant="getBgColorForLetter(moduleData.name)" :text="getFirstLetterOfAString(moduleData.name)">
            </b-avatar>
          </div>
          <!-- userName -->
          <div class="d-flex flex-column">
            <h5 class="mb-0">
              {{ moduleData.name }}
            </h5>
            <p class="mb-0 text-secondary">
              {{ getFormatttedTimeAgo(vmPostData.created_on) }}
            </p>
          </div>
        </div>
        <hr />
        <!-- description  -->
        <div class="user-post px-3 obverflow-hidden">
          <p class="my-2" v-if="vmPostData && vmPostData.post_desc" style="white-space:pre-wrap;">
            <span v-html="vmPostData.post_desc"></span>
          </p>
          <div v-if="vmPostData.post_images && vmPostData.post_images.length > 0" class="mt-2 d-flex justify-content-center align-items-center ">
            <button v-if="vmPostData.post_images && vmPostData.post_images.length > 1" class=".slide_control_btn mr-2" @click="showPrevImage" style="all:unset; cursor:pointer;">
              <i class="fas fa-chevron-circle-left slide_control_icon text-primary" size="3x"></i>
            </button>
            <div class="position-relative w-100" >
              <b-carousel id="carousel-fade" indicators :interval="0" ref="image_carousel">
                <b-carousel-slide v-for="postImage in vmPostData.post_images" :key="postImage.image"  >
                  <template #img>
                    <img class="d-block img-fluid w-100" style="height:480px; object-fit:contain;" :src="postImage.image" alt="post image">
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
            <button  v-if="vmPostData.post_images && vmPostData.post_images.length > 1" class=".slide_control_btn ml-2" @click="showNextImage" style="all:unset; cursor:pointer;">
              <i class="fas fa-chevron-circle-right slide_control_icon text-primary" size="3x"></i>
            </button>
          </div>
          <!-- video -->
          <div v-if="vmPostData && vmPostData.post_video" class="mt-2 ratio ratio-16x9" >
            <iframe :src="vmPostData.post_video" style="width: 100%; height: 480px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { socialvue } from "../../config/pluginInit"
import { Posts } from "../../FackApi/api/post"
import { Likes } from "../../FackApi/api/like"
import { Comments } from "../../FackApi/api/comment"
import ApiResponse from "../../Utils/apiResponse.js"
import Utility from "../../Utils/utility"

export default {
  name: "PostDetail",
  data () {
    return {
      cvLoadingStatus: false,
      cvCarouselHeight: 1000,
      cvCarouselWidth: "70%",
      cvPostImages: [],
      vmPostData: {},
      currentPage: "1",
      totalPage: "",
      postCommentObj: {},
      postCommentReplyCountObj: {},
      vmLikeFormData: Object.assign({}, this.initLikeFormData()),
      vmCommentFormData: Object.assign({}, this.initCommentFormData()),
      moduleData: {}
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      await socialvue.index()
      await this.postView()
    },
    /**
     * initCommentFormData
     */
    initCommentFormData () {
      return {
        "module_id": this.$route.params.post_id,
        "module_name": "POST",
        "comment": "",
        "comment_parent_id": ""
      }
    },
    /**
     * initLikeFormData
     */
    initLikeFormData () {
      return {
        "module_id": "",
        "module_name": "",
        "like_status": 0
      }
    },
    /**
     * getCommentImage
     */
    getCommentImage (comment) {
      if (comment.user_id === this.userData.user_id) {
        if (this.userData.user_image && this.userData.user_image.profile_pic) {
          return this.userData.user_image.profile_pic.image_thumbnail
        }
      }
      return false
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getFormatttedTimeAgo()
     */
    getFormatttedTimeAgo (dateTime) {
      return Utility.getFormatttedTimeAgo(dateTime)
    },
    /**
     * showPrevImage ()
     */
    showPrevImage () {
      try {
        this.$refs.image_carousel.prev()
      }
      catch (err) {
        console.error("Exception occurred at showPrevImage () and Exception", err.message)
      }
    },
    /**
     * showPrevImage ()
     */
    showNextImage () {
      try {
        this.$refs.image_carousel.next()
      }
      catch (err) {
        console.error("Exception occurred at showNextImage () and Exception", err.message)
      }
    },
    /**
     * postView
     */
    async postView () {
      try {
        this.cvLoadingStatus = true
        let postId = this.$route.params.post_id
        let postViewResp = await Posts.postContentView(this, postId)
        if (postViewResp && !postViewResp.resp_status) {
          return false
        }
        else {
          this.vmPostData = postViewResp.resp_data
          if (this.vmPostData.module_data) {
            if (this.vmPostData.post_type === 1) {
              this.moduleData = {
                "name": this.vmPostData.module_data.org_name
              }
            }
            else if (this.vmPostData.post_type === 3) {
              this.moduleData = {
                "name": this.vmPostData.module_data.club_name
              }
            }
            else if (this.vmPostData.post_type === 5) {
              this.moduleData = {
                "name": this.vmPostData.module_data.user_name
              }
            }
            this.moduleData["image"] = this.vmPostData.module_data.profile_pic ? this.vmPostData.module_data.profile_pic.image_thumbnail : null
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at postView() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * postLike()
     * @param likeStatus whether module is liked by current user
     */
    async postLike (moduleId, moduleName, likeStatus) {
      try {
        this.vmLikeFormData.module_id = moduleId
        this.vmLikeFormData.module_name = moduleName
        this.vmLikeFormData.like_status = likeStatus ? 0 : 1
        let likeAddResp = await Likes.likeAdd(this, this.vmLikeFormData)
        if (likeAddResp && !likeAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, likeAddResp)
        }
        else {
          // local updating the like for postDetail
          if (this.vmLikeFormData.like_status) {
            this.vmPostData.post_like_count += 1
            this.vmPostData.post_likedby_curr_user = 1
          }
          else {
            this.vmPostData.post_like_count -= 1
            this.vmPostData.post_likedby_curr_user = 0
          }

          // global
          this.$store.dispatch("Post/postLikeUnlikeAction", this.vmLikeFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at  postLike () and Exception", err.message)
      }
    },
    /**
     * commentLoadMore
     */
    commentLoadMore () {
      try {
        this.currentPage++
      }
      catch (err) {
        console.error("Exception occurred at commentLoadMore() and Exception:", err.message)
      }
    },
    /**
     * commentList
     */
    async commentList (currPage) {
      try {
        let commentListResp = await Comments.commentList(this, currPage, this.vmPostData.post_id)
        if (commentListResp && commentListResp.resp_status) {
          let commentListData = { ...this.postCommentObj, ...commentListResp.resp_data.comment_data }
          let commentReplyCountData = { ...this.postCommentReplyCountObj, ...commentListResp.resp_data.comment_reply_count }
          this.postCommentObj = commentListData
          this.postCommentReplyCountObj = commentReplyCountData
          this.currentPage = commentListResp.resp_data.currentPage
          this.totalPage = commentListResp.resp_data.totalPage
        }
        else {
          this.postCommentObj = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at commentList() and Exception:", err.message)
      }
    },
    /**
     * commentAdd
     */
    async commentAdd () {
      try {
        this.cvLoadingStatus = true

        // Format the comment data
        this.vmCommentFormData.comment = this.vmCommentFormData.comment.replace(/\n\r?/g, "<br>")

        let commentAddResp = await Comments.commentAdd(this, this.vmCommentFormData)
        if (commentAddResp && !commentAddResp.resp_status) {
          await ApiResponse.responseMessageDisplay(this, commentAddResp)
          return false
        }
        else {
          // make comment obj
          let commentAddData = {}
          let commentId = commentAddResp.resp_data.data.comment_id
          commentAddData[commentId] = { ...commentAddResp.resp_data.data }
          commentAddData[commentId].user_name = this.userData.user_name
          commentAddData[commentId].user_image = this.userData.user_image
          commentAddData[commentId].comment_edited = false
          commentAddData[commentId].comment_reply = false
          commentAddData[commentId].comment_show_more = false
          // make new  comment obj
          let updatedCommentObj = Object.assign({}, { ...commentAddData, ...this.postCommentObj })
          this.postCommentObj = updatedCommentObj
          updatedCommentObj = {}

          // update post count to vuex store
          let editPostCountData = {}
          editPostCountData.postId = this.vmCommentFormData.module_id
          editPostCountData.updatationType = "ADD"
          this.$store.dispatch("Post/editPostCommentCountAction", editPostCountData)
        }
        this.vmCommentFormData = Object.assign({}, this.initCommentFormData())
      }
      catch (err) {
        console.error("Exception occurred at commentAdd() and Exception:", err)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * commentDelete
     */
    async commentDelete (commentId) {
      try {
        let commentDeleteResp = await Comments.commentDelete(this, commentId)
        await ApiResponse.responseMessageDisplay(this, commentDeleteResp)
        if (commentDeleteResp && !commentDeleteResp) {
          return false
        }
        else {
          // update post count to vuex store
          let editPostCountData = {}
          editPostCountData.postId = this.vmCommentFormData.module_id
          editPostCountData.updatationType = "DEL"
          this.$store.dispatch("Post/editPostCommentCountAction", editPostCountData)
          this.$delete(this.postCommentObj, commentId)
        }
      }
      catch (err) {
        console.error("Exception occurred at commentList() and Exception:", err.message)
      }
    },
    /**
     * commentEdit()
    */
    async commentEdit (postCommentObj) {
      try {
        let editedCommentData = { ...postCommentObj, comment: this.vmComment }
        let commentEditResp = await Comments.commentEdit(this, editedCommentData)
        await ApiResponse.responseMessageDisplay(this, commentEditResp)
        if (commentEditResp && !commentEditResp) {
          this.commentEditHideInput(postCommentObj)
          return false
        }
        else {
          // update the edited value
          let commentId = postCommentObj.comment_id
          this.postCommentObj[commentId] = { ...postCommentObj, comment: this.vmComment, comment_edited: false }
        }
      }
      catch (err) {
        console.error("Exception occurred at commentEdit () and Exception:", err.message)
      }
    },
    /**
     * commentEditShowInput()
     */
    commentEditShowInput (postComment) {
      try {
        // set value to edit from input
        this.vmComment = postComment.comment
        // opening the edit form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_edited: true }
      }
      catch (err) {
        console.error("Exception occurred at commentEditShowInput() and Exception", err.message)
      }
    },
    /**
     * commentEditHideInput()
     */
    commentEditHideInput (postComment) {
      try {
        // set edit form input value to empty string
        this.vmComment = ""
        // closing the edit form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_edited: false }
      }
      catch (err) {
        console.error("Exception occurred at commentEditHideInput() and Exception", err.message)
      }
    },
    /**
     * commentReplyShowInput()
     */
    commentReplyShowInput (postComment) {
      try {
        // opening the reply form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_reply: true }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyShowInput() and Exception", err.message)
      }
    },
    /**
     * commentLike
    */
    async commentLike (moduleId, moduleName, likeStatus) {
      try {
        this.vmLikeFormData.module_id = moduleId
        this.vmLikeFormData.module_name = moduleName
        this.vmLikeFormData.like_status = likeStatus ? 0 : 1
        let likeAddResp = await Likes.likeAdd(this, this.vmLikeFormData)
        if (likeAddResp && !likeAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, likeAddResp)
        }
        else {
          let likeUnlikeData
          if (this.vmLikeFormData.like_status) {
            likeUnlikeData = { ...this.postCommentObj[moduleId], comment_likedby_curr_user: 1 }
          }
          else {
            likeUnlikeData = { ...this.postCommentObj[moduleId], comment_likedby_curr_user: 0 }
          }
          this.postCommentObj[moduleId] = likeUnlikeData
        }
      }
      catch (err) {
        console.error("Exception occurred at commentLike () and Exception", err.message)
      }
    },
    /**
     * showMoreComment()
     */
    showMoreComment (postCommentObj) {
      try {
        let commentId = postCommentObj.comment_id
        this.postCommentObj[commentId] = { ...postCommentObj, comment_show_more: true }
      }
      catch (err) {
        console.error("Exception occurred at showMoreComment() and Exception:", err.message)
      }
    }
  }
}
</script>

<style scoped>
  .w-full{
    width: 100%;
  }
    .btn-opacity{
    border: none ;
    outline : none;
    background:#fff;
    width: 100%;
  }
  .btn-opacity:hover{
    color: var(--iq-primary)
  }
  .line-wrap{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr)
  }
  @media screen and (max-width: 768px){
    .grid{
       grid-template-columns: 0.9fr 1.2fr 0.9fr
    }
  }
  .slide_control_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
  }
  .slide_control_icon{
   font-size: 2rem;
  }
  .carousel-indicators li {
    border-color: #e100ff;
    border-width: 12px;
    border-style: none solid none solid;
    background-color: #e100ff;
    height: 2px;
    margin: 0 16px;
    opacity: 1;
    padding: 1px;
    position: relative;
  }
  .carousel-indicators li::after {
    bottom: -7px;
    content: none;
    left: -7px;
    padding: 1px;
    position: absolute;
    right: -7px;
    top: -7px;
  }
  .carousel-indicators li.active {
    background-color: #7f00ff;
    border-color: #7f00ff;
    border-width: 12px;
    border-style: none solid none solid;
  }
</style>
